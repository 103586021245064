.termdiv{
    background-color: #ffffff;
    width: 50%;
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
   
}
.termdiv h1{
    margin: 0px 0px 20px 0px;
    text-align: center;
}
.terminnerDiv ol{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.pointsp{
    text-align: justify;
}

@media only screen and (max-width: 600px) {
    .termdiv {
      width: 70%;
      padding: 1rem;
    }
  }