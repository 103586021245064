.FirstFormDiv {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.titlefirst {
  font-weight: 700;

  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0;
}
.FieldDiv {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.FieldDiv2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0;
  margin-bottom: 1rem;
}
.office{
  margin-bottom: 0;
}
.clrinput {
  padding: 1rem;
  border-radius: 50px;
  border: 1px solid #e5e7eb;
  outline: 1px solid transparent;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.btns {
  display: flex;
  gap: 2rem;
  width: max-content;
  margin: auto;
}
.prev {
  color: #fff;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  font-family: inherit;
  padding: 8px 30px;
  margin: 5px;
  font-size: 18px;
  background: #88c346;
}
.next {
  color: #fff;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  font-family: inherit;
  padding: 8px 30px;
  margin: 5px;
  font-size: 18px;
  background: #88c346;
}
.error {
  outline: 1px solid red;
  padding: 1rem;
  border-radius: 50px;
  border: none;

  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.AddLocation {
  background-color: #3b82f6;
  width: 180px;
  padding: 10px 12px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.checkboxdiv {
  display: flex;
  gap: 2px;
}
.para {
  text-align: center;
  font-size: larger;
}
.quizdiv {
  display: flex;
  flex-direction: row;
  background-color: #e5e7eb;
  padding: 1rem 2rem;
  border: 25px;
}
.verifydiv {
  width: 100px;
  height: 100px;
}
.Submitbtn {
  color: #fff;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  font-family: inherit;
  padding: 8px 30px;
  margin: 5px;
  font-size: 18px;
  background:linear-gradient(to right, #1ca9af, #88c346);;
  background-color: #44c767;
  margin-bottom: 1rem;
}
.termslink {
  color: #88c346;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
}
.termslink {
  padding: 0 5px;
}
.randomdiv {
  gap: 1rem;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 2rem;
}
.randomnumspan {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btndiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}
.disbledbtn {
  background-color: rgba(128, 128, 128, 0.429);
  color: #fff;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  font-family: inherit;
  padding: 8px 30px;
  margin: 5px;
  font-size: 18px;
}
.FieldDiv input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.addressdiv {
  width: 90%;
  gap: 1rem;
  display: flex;
  justify-content: space-between;
}
.addressdiv input{
  width: 30%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

