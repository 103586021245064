.termdiv{
    background-color: #ffffff;
    width: 50%;
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    flex-direction: column;   
}
.termdiv h1.main_title{
    margin: 0px 0px 20px 0px;
    text-align: center;
}
.termdiv h3{ margin-bottom: 10px; margin-top: 10px;}
.termdiv p{ margin-bottom: 15px; margin-top: 0px;}
.termdiv p a{color: #1ca9af;}
.termdiv p a:hover{ text-decoration: none;}
.terminnerDiv ol{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.pointsp{
    text-align: justify;
}

@media only screen and (max-width: 600px) {
    .termdiv {
      width: 70%;
      padding: 1rem;
    }
  }
  