.App {
  background-image: linear-gradient(to right, #1ca9af, #88c346);
  min-height: 100vh;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  justify-content: space-between;
}
.form_div {
  background-color: white;
  height: max-content;
  width: 55%;
  border-radius: 1rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  margin: 1rem 0 0.5rem;
}
.formtitle {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.individualForms {
  width: 100%;
}
.errorMessage {
  margin-top: 1.5rem;
  text-align: center;
  color: red;
  font-size: larger;
  font-weight: 700;
}
.footer {
  color: white;
}

.imgrankdiv {
  background-color: #ffffff;
  width: 20rem;
  border-radius: 13px;
  object-fit: contain;

  padding: 10px 20px;
}
img {
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 36px; /* Example font size */
  font-weight: bold; /* Example font weight */
}
h2 {
  font-size: 24px; /* Example font size */
  font-weight: bold; /* Example font weight */
  margin-bottom: 1rem;
}
h3 {
  font-size: 20px; /* Example font size */
  font-weight: bold; /* Example font weight */

}

ol {
  list-style-type: decimal; /* Example list style */
   margin: 1rem;
  padding: 0; /* Example padding */
  font-size: 16px; /* Example font size */
}
.text_white{color:#fff;}
.footer_coptyright{
  display: flex;
  gap: 1.25rem;
  align-items: center;
  margin-bottom: 10px;
}
.privacy_link, .privacy_link a{
  color: #fff;
}
.privacy_link a{
  text-decoration: none;
}
.privacy_link a:hover{
  text-decoration: underline;
}


@media only screen and (max-width: 600px) {
  .form_div {
    width: 80%;
  }
  .imgrankdiv {
    width: 50%;
    margin: auto;
  }
}
